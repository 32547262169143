<div class="img">
  <img *ngIf="data.img" [src]="getPathImg(data.img)" alt="img" />
</div>
<div class="popup" [class.isFirefox]="isFirefox">
  <div class="pseudo-img"></div>
  <h2 class="accent text-align-center title bold-5">
    {{ data.text | translate: data.translateParam }}
  </h2>
  <div class="btn" *ngIf="data.back">
    <button mat-flat-button color="primary" class="h6 dark shadow" (click)="onClose()">
      {{ data.back | translate }}
    </button>
  </div>
</div>
